import "./App.css";
import React, { useState, useEffect } from 'react';
import Sidebar from "./components/Sidebar/Sidebar";
import Main from "./components/Main/Main"
import Clips from "./components/Clips/Clips"

import { Routes, Route, Navigate } from "react-router-dom";
import Multiview from "./components/mutiview/Multiview";
import axios from 'axios'

import Data from './assets/data/liste_streamer'
export default function App() {
	const [listSteamer, setListSteamer] = useState([])
	const [mainSteamer, setMainSteamer] = useState(null)
	const [isMenuOpen, setisMenuOpen] = useState(false);

	useEffect(() => {
		// const interval = setInterval(() => {
		// }, 6000);

		const perChunk = 100 // items per chunk

		const result = Data.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk)
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [] // start a new chunk
			}
			resultArray[chunkIndex].push(item)
			return resultArray
		}, [])


		findStreams(result).then(streams => {
			streams.sort((a, b) => b.viewer_count - a.viewer_count);
			setListSteamer(streams)
			setMainSteamer(streams[Math.floor(Math.random() * streams.length)])
		});

	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			const perChunk = 100 // items per chunk

			const result = Data.reduce((resultArray, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)
				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [] // start a new chunk
				}
				resultArray[chunkIndex].push(item)
				return resultArray
			}, [])


			findStreams(result).then(streams => {
				streams.sort((a, b) => b.viewer_count - a.viewer_count);

				setListSteamer(streams)
			});
		}, 60000);

		return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	}, [])

	const findStreams = (result) => {
		const taskIds = Array.from(new Set(result.map((tabInside) => {
			var stream = "?"
			for (let index = 0; index < tabInside.length; index++) {
				stream += `user_login=${tabInside[index].name}&`
			}
			return stream
		})))
		const taskRateMap = [];

		return Promise.all(taskIds.map(async taskId => {
			const res = await getStreamTwitch(taskId);
			res.forEach(el => {
				taskRateMap.push(el);
			});
		})).then(() => {
			return taskRateMap;
		});
	}

	const getStreamTwitch = async (stream) => {
		var arrayStream = []
		let config = {
			headers: {
				'Authorization': process.env.REACT_APP_Authorization,
				'Client-ID': process.env.REACT_APP_ClientID
			}
		}
		return await axios.get('https://api.twitch.tv/helix/streams' + stream, config)
			.then(async (res) => {
				await res.data.data.forEach((el) => {
					if ((el.title.toLowerCase().includes("failyv") || el.title.toLowerCase().includes("faily v")) && el.game_name === "Grand Theft Auto V") {
						arrayStream.push(el)
					}
				})
				// console.log(arrayStream)
				return arrayStream
			})
			.catch((err) => { console.log(err) });

	}
	return (
		<div className="App">
			<Sidebar setisMenuOpen={setisMenuOpen} isMenuOpen={isMenuOpen} />
			<Routes>
				<Route path="/" index element={<Main listSteamer={listSteamer} mainSteamer={mainSteamer} setMainSteamer={setMainSteamer} setisMenuOpen={setisMenuOpen} />} />
				<Route path="multipov" element={<Multiview listSteamer={listSteamer} setisMenuOpen={setisMenuOpen} />} />
				<Route path="clips" element={<Clips />} />
				<Route path="*" element={<Navigate to="/" replace={true} />
				} />
			</Routes>
		</div>
	);
}
