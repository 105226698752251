import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather'
import { isMobile, useMobileOrientation } from 'react-device-detect';

import "./Multiview.css"

const Option = ({ setshowOption, listSteamer, SelectStreamer, setSelectStreamer }) => {
	const [nbSelect, setnbSelect] = useState(0);
	const [fade, setfade] = useState(false)

	const [listLocalStream, setlistLocalStream] = useState([]);


	const { isLandscape } = useMobileOrientation()

	useEffect(() => {
		var test = []
		setnbSelect(SelectStreamer.length)
		SelectStreamer.forEach(element => {
			test.push(listSteamer.find(x => x.user_login === element));
		});
		setlistLocalStream(test)
	}, [SelectStreamer]);

	const addStreamer = (e) => {
		localStorage.setItem("multipovstreamer", JSON.stringify(SelectStreamer))
		setfade(true);
	}

	const addSelect = () => {
		var num = isMobile ? 4 : 8
		if (nbSelect < num) {
			setnbSelect(nbSelect + 1)
		}
	}

	const removeStream = (keyNb) => {
		setnbSelect(nbSelect - 1)

		setSelectStreamer(SelectStreamer.filter(item => item !== keyNb))
	}

	const changeStream = (e, key) => {
		var array = SelectStreamer

		if (array[key] !== undefined) {
			array.splice(key, 1);
			array.splice(key, 0, e.target.value)
		} else {
			array.push(e.target.value)
		}
		setSelectStreamer(array);
		localStorage.setItem("multipovstreamer", JSON.stringify(array))
	}
	return (
		<div className={isLandscape ? 'option landscape' : 'option'}>
			<div className={fade ? 'container fade' : 'container'} onAnimationEnd={() => { fade && setshowOption(false) }}>
				<div className="header">
					<div className="header_title">
						<h2>Sélection des POV</h2>
					</div>
					<div className="header_close">
						<Icon.X size={26} color="white" onClick={() => { setfade(true); }} />
					</div>
				</div>
				<div className="body">
					<div className="form formPerso" >
						<div className="liste">
							{
								[...Array(nbSelect)].map((e, keyNb) =>
									<div key={keyNb} style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
										<select defaultValue={listLocalStream.length === nbSelect ? listLocalStream[keyNb].user_login : 0} onChange={(e) => { changeStream(e, keyNb) }}>
											<option value='0' disabled>Sélectionner un streameur</option>
											{
												listSteamer.map((val, key) => {
													return (<option key={key} value={val.user_login}>{val.user_name} </option>)
												})
											}
										</select>
										<Icon.X size={18} color="red" style={{ marginLeft: "15px", cursor: "pointer" }} onClick={() => { removeStream(listLocalStream[keyNb].user_login) }} />
									</div>
								)
							}
							{/* {nbSelect.map(child => child)} */}
							<p style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", marginTop: "15px", cursor: "pointer" }} onClick={addSelect}>
								<Icon.PlusCircle style={{ marginRight: "15px" }} />
								Ajouter un autre streamers
							</p>
						</div>
						<div className="button_pannel">
							<div className="button_cancel">
								<button type="button" className="cancel" onClick={() => { setfade(true); }} >Annuler</button>
							</div>
							<div className="button_Save">
								<button type="submit" className="save" onClick={addStreamer}>Sauvegarder</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function Multiview({ listSteamer, setisMenuOpen }) {
	const [showOption, setshowOption] = useState(false);
	const [SelectStreamer, setSelectStreamer] = useState([]);
	const { isLandscape } = useMobileOrientation()


	useEffect(() => {
		if (!localStorage.getItem("multipovstreamer") || localStorage.getItem("multipovstreamer").length === 2 || localStorage.getItem("multipovstreamer") === null) {
			setshowOption(true)
		} else {
			var listLocal = JSON.parse(localStorage.getItem("multipovstreamer"))
			listLocal.forEach(element => {
				if (listSteamer.some(e => e.user_login === element)) {
				}
				else {
					listLocal = listLocal.filter(function (item) {
						return item !== element
					})
				}
			});
			setSelectStreamer(listLocal)

		}
	}, [listSteamer]);

	const openOption = () => {
		setshowOption(true)
	}

	return (
		<div className={isMobile ? "Multiview mobile" : 'Multiview'}>
			{
				showOption ? <Option setshowOption={setshowOption} listSteamer={listSteamer} SelectStreamer={SelectStreamer} setSelectStreamer={setSelectStreamer} /> : null
			}
			<div className='topBar'>
				{isMobile && !isLandscape &&
					<div style={{ paddingRight: "5px", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
						<Icon.Menu color='white' onClick={() => { setisMenuOpen(true) }} />
					</div>}
				<p>Liste des streams sélectionnés :
					{SelectStreamer.map((pov, key) => {
						return " " + pov + ","
					})}

				</p>
				<Icon.Settings size={24} color={"white"} style={{ cursor: "pointer", minWidth: "20px" }} onClick={openOption} />
			</div>
			<div className={isLandscape ? 'lists landscape' : 'lists'} data-nb={SelectStreamer.length}>
				{
					SelectStreamer &&
					SelectStreamer.map((pov, key) => {
						return (
							<div key={key} className='livestream' data-stream={key}>
								<iframe
									src={`https://player.twitch.tv/?channel=${pov}&parent=${process.env.REACT_APP_Parent}${key > 0 ? "&muted=true" : ""}`}
									width="100%" height="100%"
									title={`stream ${pov}`}
									allowFullScreen>
								</iframe>
							</div>
						)
					})
				}
			</div>
		</div >
	)
}

export default Multiview