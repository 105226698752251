import React, { useState } from 'react'
import { isMobile, useMobileOrientation } from 'react-device-detect';
import "./Main.css"
import * as Icon from 'react-feather'

function Main({ listSteamer, mainSteamer, setMainSteamer, setisMenuOpen }) {
	const [search, setsearch] = useState(null);
	// const MINUTE_MS = 600;

	const { isLandscape } = useMobileOrientation()


	const makeMain = (e) => {
		setMainSteamer(e)

	}

	const items = listSteamer.filter(
		(data) =>
			(search == null) ?
				data
				:
				(data.title.toLowerCase().includes(search.toLowerCase()) || data.user_name.toLowerCase().includes(search.toLowerCase())) ?
					data
					:
					""
	).map((data, key) => {
		return (
			<div className={data.user_login !== mainSteamer.user_login ? " listItem" : " listItem active"} key={key} onClick={() => { makeMain(data); window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
				{/* <div className={mainStream !== null ? " listItem active" : " listItem active"} key={key} onClick={() => { makeMain(data) }}> */}
				<div className='itemThumbnail'>
					<img src={`https://static-cdn.jtvnw.net/previews-ttv/live_user_${data.user_login}-1280x720.jpg`} alt={"Thumbnail" + data.user_login} />
				</div>
				<div className='itemStreamInfo'>
					<div>
						<div className='streamer'>
							<div className='profilePicture'></div>
							<p className='name'> {data.user_name}</p>
						</div>
						<div className='nbViewer'>
							<div className='dot'></div>
							<div className='nbtitle'>{data.viewer_count} spectateurs</div>
						</div>
					</div>
					<div className='streamTitle'>
						<p title={data.title}> {isMobile ? data.title.length > 20 ? data.title.substr(0, 20 - 3) + "..." : data.title : data.title.length > 40 ? data.title.substr(0, 40 - 3) + "..." : data.title}</p>
					</div>
				</div>
			</div>

		)
	})
	return (
		<div className={isMobile ? "Main mobile" : 'Main'}>
			{isMobile &&
				<div style={{ width: "100%", padding: "10px", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
					<Icon.Menu color='white' onClick={() => { setisMenuOpen(true) }} />
				</div>}
			<div className='main_container'>
				<div className='main_stream'>
					<div className='lecteur' >
						{
							listSteamer.length > 0 ?
								<iframe
									src={`https://player.twitch.tv/?channel=${mainSteamer.user_login}&parent=${process.env.REACT_APP_Parent}&muted=false`}
									width="100%" height="100%"
									title={`stream ${mainSteamer.user_login}`}
									allowFullScreen>
								</iframe>
								:
								<div style={{
									width: "100 %",
									height: " 100 %",
									color: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: "column"
								}}>
									<h1>Aucun stream en cours</h1>
									<p>Ne quittez pas cette page, elle sera mise à jour automatiquement lorsque qu'un stream sera actif ! 😀</p>
								</div>
						}
					</div>
				</div>
				{
					listSteamer.length > 0 &&
					<div className='list'>
						<div className='list-title'>
							<p>Liste des streams en cours</p>
							<input type={"text"} placeholder='🔍 Nom du streameur' onChange={(e) => { setsearch(e.target.value) }} />
						</div>
						<div className={isLandscape ? 'lists landscape' : "lists"} style={{ gridTemplateRows: `repeat(${Math.ceil(listSteamer.length / 4)}, 1fr)` }}>
							{
								items
							}
						</div>
					</div>
				}
			</div>
		</div >
	)
}

export default Main