import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather'
import { BiNews, BiUser, BiMoviePlay } from "react-icons/bi";
import { GiRevolver } from "react-icons/gi";
import { isMobile } from 'react-device-detect';


import FailyVLogo from "../../assets/img/failyvlogo.png"

import { Link } from "react-router-dom"

import './Sidebar.css'
function Sidebar({ setisMenuOpen, isMenuOpen }) {
	const [SelectMenu, setSelectMenu] = useState("/")
	useEffect(() => {
		setSelectMenu(window.location.pathname)
	}, []);

	const closeMobilePanel = () => {
		setisMenuOpen(false)
	}
	return (
		<div className={isMobile ? isMenuOpen ? 'sidebar mobile menuOpen' : 'sidebar mobile' : "sidebar"}>
			<div className="sidebar-top">
				{
					isMobile &&
					<div style={{ width: "100%", padding: "10px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
						<Icon.X color='white' onClick={closeMobilePanel} />
					</div>
				}
				<div className='sidebar-menu'>
					<div className="menu_title">
						<p>Application</p>
					</div>
					<div className='menu'>
						<div className={`menu_pages ${SelectMenu === "/" ? "selectMenu" : ""}`} onClick={() => { setSelectMenu("/"); if (isMobile) setisMenuOpen(false) }}>
							<Link to="/">
								<div className="menu_icon" >
									<Icon.Tv className="icon" />
								</div>
								<div className="menu_text">
									<p>Streams en cours</p>
								</div>
							</Link>
						</div>
						<div className={`menu_pages ${SelectMenu === "/multipov" ? "selectMenu" : ""}`} onClick={() => { setSelectMenu("/multipov"); if (isMobile) setisMenuOpen(false) }} >
							<Link to="/multipov">
								<div className="menu_icon" >
									<Icon.Grid size={28} className="icon" />
								</div>
								<div className="menu_text">
									<p>Multi POV</p>
								</div>
							</Link>
						</div>
						<div className={`menu_pages ${SelectMenu === "/clips" ? "selectMenu" : ""}`} onClick={() => { setSelectMenu("/clips"); if (isMobile) setisMenuOpen(false) }} >
							<a href='https://clips.failyv.com/' target={"_blank"} rel="noreferrer">
								<div className="menu_icon" >
									<BiMoviePlay size={28} className="icon" />
								</div>
								<div className="menu_text">
									<p>Clips</p>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div className='sidebar-menu'>
					<div className="menu_title">
						<p>Autres liens</p>
					</div>
					<div className='menu'>
						<div className="menu_pages" >
							<a href='https://panel.failyv.com/weazelnews/' target={"_blank"} rel="noreferrer">
								<div className="menu_icon" >
									<BiNews className="icon" size={28} />
								</div>
								<div className="menu_text">
									<p>Weazel News</p>
								</div>
							</a>
						</div>
						<div className="menu_pages" >
							<a href='https://panel.failyv.com/' target={"_blank"} rel="noreferrer">
								<div className="menu_icon" >
									<BiUser size={28} className="icon" />
								</div>
								<div className="menu_text">
									<p>Panel FailyV</p>
								</div>
							</a>
						</div>
						<div className="menu_pages" >
							<a href='https://panel.failydeath.com/' target={"_blank"} rel="noreferrer">
								<div className="menu_icon" >
									<GiRevolver size={28} className="icon" />
								</div>
								<div className="menu_text">
									<p>FailyDeath</p>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='sidebar-bottom'>
				<div className='sidebar-reseaux'>
					<div className="logoReseau twitter">
						<a href='https://www.twitter.com/FailyV_GTARP' target={"_blank"} rel="noreferrer">
							<svg width="100%" height="100%" viewBox="0 0 174 242" version="1.1">
								<g transform="matrix(7.14738,0,0,7.14738,-0.841281,35.3816)">
									<path d="M23.643,4.937C22.808,5.307 21.911,5.557 20.968,5.67C21.93,5.094 22.668,4.18 23.016,3.092C22.116,3.626 21.119,4.014 20.058,4.222C19.208,3.318 17.998,2.752 16.658,2.752C14.086,2.752 12,4.838 12,7.412C12,7.776 12.042,8.13 12.12,8.472C8.247,8.277 4.816,6.422 2.518,3.604C2.118,4.294 1.888,5.094 1.888,5.946C1.888,7.562 2.711,8.989 3.96,9.824C3.196,9.799 2.478,9.59 1.85,9.241L1.85,9.301C1.85,11.558 3.455,13.441 5.587,13.869C5.195,13.975 4.784,14.031 4.36,14.031C4.06,14.031 3.767,14.003 3.483,13.949C4.076,15.799 5.796,17.147 7.835,17.183C6.24,18.433 4.231,19.178 2.049,19.178C1.673,19.178 1.302,19.156 0.937,19.113C2.999,20.436 5.447,21.206 8.077,21.206C16.647,21.206 21.332,14.108 21.332,7.952C21.332,7.752 21.327,7.55 21.318,7.35C22.228,6.692 23.018,5.873 23.641,4.94L23.643,4.937Z" style={{ fill: "#3D50FA", fillRule: "nonzero" }} />
								</g>
							</svg>
						</a>
					</div>
					<div className="logoReseau discord">
						<a href='https://discord.com/invite/failyv' target={"_blank"} rel="noreferrer">
							<svg width="100%" height="100%" viewBox="0 0 174 242" version="1.1" >
								<g transform="matrix(5.67329,0,0,5.67329,-4.73255e-07,26.6847)">
									<path d="M26.002,6.953C24.002,6.038 21.879,5.372 19.662,5C19.383,5.48 19.073,6.131 18.856,6.643C16.499,6.302 14.159,6.302 11.834,6.643C11.617,6.131 11.291,5.48 11.028,5C8.796,5.372 6.672,6.038 4.687,6.953C0.673,12.874 -0.412,18.655 0.13,24.359C2.796,26.296 5.369,27.474 7.897,28.249C8.517,27.412 9.075,26.513 9.555,25.568C8.641,25.227 7.773,24.808 6.936,24.312C7.153,24.157 7.37,23.987 7.571,23.816C12.624,26.126 18.097,26.126 23.088,23.816C23.305,23.987 23.506,24.157 23.723,24.312C22.886,24.808 22.018,25.227 21.104,25.568C21.584,26.513 22.142,27.412 22.762,28.249C25.289,27.474 27.877,26.296 30.529,24.359C31.195,17.756 29.473,12.021 26.002,6.953ZM10.253,20.84C8.734,20.84 7.494,19.461 7.494,17.771C7.494,16.082 8.703,14.703 10.253,14.703C11.787,14.703 13.043,16.082 13.012,17.771C13.012,19.461 11.787,20.84 10.253,20.84ZM20.437,20.84C18.918,20.84 17.677,19.461 17.677,17.771C17.677,16.082 18.887,14.703 20.437,14.703C21.972,14.703 23.227,16.082 23.196,17.771C23.196,19.461 21.987,20.84 20.437,20.84Z" style={{ fill: "#3D50FA", fillRule: 'nonzero' }} />
								</g>
							</svg>
						</a>
					</div>
					<div className="logoReseau fandom">
						<a href='https://failyv.fandom.com/fr/wiki/Wiki_FailyV' target={"_blank"} rel="noreferrer">
							<svg width="100%" height="100%" viewBox="0 0 174 242" version="1.1" >
								<g>
									<path d="M130.565,119.441L66.893,55.739C66.564,55.411 66.145,55.187 65.689,55.096C65.233,55.006 64.76,55.052 64.33,55.231C63.901,55.409 63.534,55.71 63.276,56.097C63.017,56.484 62.879,56.94 62.879,57.405L62.879,110.967L43.598,91.681C43.269,91.352 42.85,91.128 42.394,91.038C41.937,90.947 41.465,90.994 41.035,91.172C40.605,91.351 40.239,91.652 39.98,92.039C39.722,92.426 39.584,92.881 39.584,93.347L39.584,148.659C39.582,150.387 39.922,152.099 40.583,153.696C41.245,155.292 42.214,156.742 43.437,157.963L68.528,183.087C70.996,185.556 74.341,186.945 77.83,186.949L96.186,186.949C99.674,186.947 103.019,185.561 105.486,183.093L130.571,157.968C131.791,156.747 132.759,155.296 133.419,153.699C134.078,152.103 134.417,150.392 134.416,148.664L134.416,128.753C134.417,127.024 134.077,125.311 133.416,123.713C132.756,122.115 131.787,120.664 130.565,119.441ZM111.142,142.738C111.143,143.782 110.73,144.784 109.993,145.524L89.768,165.853C89.402,166.222 88.966,166.515 88.485,166.715C88.006,166.914 87.49,167.017 86.97,167.017C86.45,167.017 85.935,166.914 85.455,166.715C84.974,166.515 84.538,166.222 84.171,165.853L64.011,145.524C63.275,144.787 62.859,143.788 62.858,142.746L62.858,135.012C62.858,134.494 62.961,133.981 63.16,133.502C63.359,133.024 63.65,132.589 64.017,132.224L71.275,124.96C71.642,124.592 72.077,124.301 72.556,124.102C73.035,123.903 73.548,123.801 74.067,123.801C74.586,123.801 75.099,123.903 75.578,124.102C76.057,124.301 76.492,124.592 76.859,124.96L87.009,135.122L97.144,124.957C97.511,124.589 97.945,124.298 98.425,124.099C98.904,123.9 99.417,123.798 99.936,123.798C100.454,123.798 100.968,123.9 101.447,124.099C101.926,124.298 102.361,124.589 102.727,124.957L109.985,132.221C110.352,132.587 110.643,133.022 110.841,133.5C111.04,133.978 111.142,134.492 111.142,135.009L111.142,142.738Z" style={{ fill: "#3D50FA", fillRule: "nonzero" }} />
								</g>
							</svg>
						</a>
					</div>
				</div>

				<div className='sidebar-logo'>
					<div>
						<img src={FailyVLogo} alt="logo FailyV" />
					</div>
				</div>
			</div>
		</div >
	)
}

export default Sidebar